import { graphql } from "gatsby";
import React, { useState } from "react";
import Menu from "../components/menu";
import ArticlePageLayout from "../components/article-page";

import { Helmet } from "react-helmet";

export default function ArticlePage({ data }) {
  const { title } = data.enYaml;
  const { description } = data.enYaml;
  const { date } = data.enYaml;
  const { link } = data.enYaml;
  const { alternativeLang } = data.enYaml;
  const { publicURL } = data.enYaml.cover;
  var schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://extra.ilsalice.org/" + link,
    },
    headline: { title },
    description: { description },
    image: "https://extra.ilsalice.org" + publicURL,
    author: {
      "@type": "Organization",
      name: "ilSalice",
    },
    publisher: {
      "@type": "Organization",
      name: "ilSalice",
      logo: {
        "@type": "ImageObject",
        url: "https://extra.ilsalice.org/icons/ilsaliceEX.png",
      },
    },
    datePublished: { date },
  };
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href={"https://extra.ilsalice.org/" + link} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={"https://extra.ilsalice.org" + publicURL}
        />
        <meta
          property="og:url"
          content={"https://extra.ilsalice.org/" + link}
        />
        <meta property="og:type" content="article" />
        {alternativeLang !== "none" && (
          <link
            rel="alternate"
            href={"https://extra.ilsalice.org/it/" + alternativeLang}
            hreflang="it"
          ></link>
        )}
        {alternativeLang !== "none" && (
          <link
            rel="alternate"
            href={"https://extra.ilsalice.org/" + link}
            hreflang="en"
          ></link>
        )}

        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <Menu></Menu>
      <ArticlePageLayout
        language="en"
        h1={data.enYaml.h1}
        cover={data.enYaml.cover}
        coveralt={data.enYaml.coveralt}
        subtitle={data.enYaml.subtitle}
        content={data.enYaml.content}
        category={data.enYaml.category}
        author={data.enYaml.author}
        date={data.enYaml.date}
        related={data.allEnYaml.nodes}
        data={data}
      />
    </>
  );
}

export const query = graphql`
  query CreatePage($link: String, $category: String) {
    enYaml(link: { eq: $link }, version: { eq: 1 }) {
      title
      link
      language
      h1
      description
      coveralt
      author
      date(formatString: "DD MMMM YYYY")
      subtitle
      alternativeLang
      cover {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1500
            aspectRatio: 3
            placeholder: NONE
          )
        }
      }
      content {
        url {
          childImageSharp {
            gatsbyImageData(width: 900)
          }
        }
        type
        text
        alt
        title
        description
        image
        link
        topic
        source
      }
    }
    allEnYaml(filter: { category: { eq: $category } }, limit: 3) {
      nodes {
        cover {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, width: 30, height: 30)
          }
        }
        coveralt
        h1
        link
      }
    }
    bottom: allEnYaml(limit: 3) {
      nodes {
        cover {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, width: 200, height: 200)
          }
        }
        coveralt
        category
        h1
        link
        description
      }
    }
  }
`;
